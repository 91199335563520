<template>
    <v-container>
        <v-btn class="mb-4" @click="edit(null)">
            Neu <v-icon right small>fas fa-plus</v-icon>
        </v-btn>
        <v-expansion-panels v-model="selected" tile mandatory focusable>
            <v-expansion-panel v-if="editItem != null" :disabled="editLoading">
                <v-expansion-panel-header>
                    {{editItem.ID == null ? 'Erstellen' : `[${editItem.ID}] '${editItem.name}' bearbeiten`}}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-form>
                        <v-container>
                            <v-row>
                                <!--v-col cols="4">
                                    <v-card tile color="box_accent" class="px-2 pt-2">
                                        <v-img height="250px" :src="image_preview" contain />
                                        <v-file-input :rules="image_rules" v-model="editItem.image_upload" accept="image/png, image/jpeg" prepend-icon="" placeholder="Bild" class="pa-0 mb-0 mt-5" show-size>

                                        </v-file-input>
                                    </v-card>
                                </v-col-->
                                <v-col>
                                    <v-text-field v-model="editItem.name" :rules="rule_notempty" label="Name" required></v-text-field>
                                    <v-text-field v-model="editItem.short" :rules="rule_notempty" label="Kürzel" required></v-text-field>
                                    <axz-file-picker v-model="editItem.image" :rules="file_rules" label="Bild" accept="image/*" required></axz-file-picker>
                                    <axz-file-picker v-model="editItem.banner" :rules="file_rules" label="Banner" accept="image/*" required></axz-file-picker>
                                </v-col>
                            </v-row>
                            <v-row justify="end" class="mt-4">
                                <v-btn v-if="editItem.ID == null" @click="create()" :loading="editLoading">
                                    Anlegen <v-icon right small>fas fa-save</v-icon>
                                </v-btn>
                                <v-btn v-else @click="update()" :loading="editLoading">
                                    Speichern <v-icon right small>fas fa-save</v-icon>
                                </v-btn>
                            </v-row>
                            
                        </v-container>
                    </v-form>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>
                    Games
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-data-table :headers="cols" :items="$db.games.data" item-key="ID" :items-per-page="-1" dense :hide-default-footer="true" :loading="loading">
                        <template v-slot:item.actions="{ item }">
                            <v-icon small class="ml-2" @click="edit(item)">fas fa-pen</v-icon>
                        </template>
                    </v-data-table>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </v-container>
</template>

<script>
export default {
    data () {
        return {
            loading: false,
            cols: [
                //{ text: 'ID', value: 'ID' },
                { text: 'Name', value: 'name' },
                { text: 'Kürzel', value: 'short' },
                { text: 'Aktionen', value: 'actions', align: 'end', sortable: false },
            ],
            selected: 0,
            editItem: null,
            editLoading: false,
            file_rules: [
                value => !!value && value.size < 20000000 || 'File size should be less than 20 MB!',
            ],
            rule_notempty: [
                v => !!v || 'required',
            ],
        }
    },
    computed: {
        image_preview () {
            if(this.editItem.image_upload != null)
                return URL.createObjectURL(this.editItem.image_upload);
            else if(this.editItem.image != null)
                return this.editItem.image;
            else return null;
        }
    },
    watch: {
        selected () {
            if(this.selected != 1 && this.editItem != null){
                this.editItem = null;
                this.loadGames(); //or clone edit item !=??!?!?
            }
        },
    },
    methods: {
        loadGames(){
            this.loading = true;
            this.$db.games.load().then(() => {
                this.loading = false;
            });
        },
        edit(item) {
            if(item != null) {
                this.editItem = Object.assign(item);
            }
            else {
                this.editItem = {
                    ID: null,
                    name: null,
                    short: null,
                    image: null,
                    banner: null,
                }
            }
            this.selected = 1;
        },
        async create(){
            this.editLoading = true;
            this.$db.games.create(
                this.editItem.name, 
                this.editItem.short,
                await this.$db.files.create(this.editItem.image),
                await this.$db.files.create(this.editItem.banner)
            ).then(() => {
                this.editLoading = false;
                this.editItem = null;
                this.loadGames();
            });
        },
        async update(){
            this.editLoading = true;
            this.$db.games.update(
                this.editItem.ID,
                this.editItem.name, 
                this.editItem.short,
                typeof this.editItem.image == 'object' ? await this.$db.files.createOrUpdate(this.editItem.image.identifier, this.editItem.image) : this.editItem.image,
                typeof this.editItem.banner == 'object' ? await this.$db.files.createOrUpdate(this.editItem.banner.identifier, this.editItem.banner) : this.editItem.banner
            ).then(() => {
                this.editLoading = false;
                this.editItem = null;
                this.loadGames();
            });
        }
    },
    mounted() {
        this.loadGames();
    }
}
</script>